import { createState, Downgraded, useState } from "@hookstate/core"
import { fetchDataRest } from "../backendServices/backendServices"
import { isNullOrEmpty } from "../utils/Arrays"
import { compareStrings } from "../utils/Strings"
import { parseResponse } from "./ReferenceDataMapper"

let initialState = {
    languages: [],
    countries: [],
    categories: [],
    categoriesMap: {},
    personFunction: [],
    accessMethod: [],
}

export interface CategoryLookup {
    [key: string]: RefCategory
}

// export interface RefLanguage {
//     id: string
//     label: string
//     value: string
// }

export interface RefAccessMethod {
    id: string
    label: string
    value: string
}

export interface RefCountry {
    name: string
    value: string
}

export interface CategoryFromResponse {
    id: string | null
    alias: string | null
    name: string | null
    types: string | null
    parentId: string | null
    subCategories: RefCategory[] | null
    synthetic: boolean | false
    code: string | null
}

export interface RefCategory extends CategoryFromResponse {
    subCategories: RefCategory[] | null
}

export interface RefPersonFunctions {
    name: string
    value: string
}

export interface RefProductProperties {
    name: string
    value: string
}

export interface ReferenceData {
    // languages: RefLanguage[]
    countries: RefCountry[]
    categories: RefCategory[]
    categoriesMap: CategoryLookup
    personFunction: RefPersonFunctions[]
    accessMethod: RefAccessMethod[]
}

export interface ReferenceDataState {
    init(): Promise<any>
    // getLanguages(): RefLanguage[]
    getCountries(): RefCountry[]
    getCategories(type: string, whitelist: string[]): RefCategory[]
    getCategory(id: string): RefCategory | undefined
    findRootByCategoryId(id: string): RefCategory | undefined
    getPersonFunctions(): RefPersonFunctions[]
    getAccessMethod(): RefAccessMethod[]
}

const referenceDataState = createState<ReferenceData>(initialState)

export async function loadProductProperties(topic: string, propertyId: any, queryParams: object | null) {
    return await fetchDataRest(`/selfservice/0/${topic}/${propertyId}/productpropertyenum/list`, queryParams, "GET", undefined, undefined, undefined)
}

export async function loadProductTypes(path: string, queryParams: object | null) {
    return await fetchDataRest(`/${path}/list`, queryParams, "GET", undefined, undefined, undefined)
}

async function loadReferenceData() {
    const response = await fetchDataRest("/initdata", null, undefined, undefined, "/webservice")
    return parseResponse(response)
}

export function useReferenceDataState(): ReferenceDataState {
    const state = useState(referenceDataState)

    return {
        init() {
            const refData = loadReferenceData()
            refData.then((result) => state.set({ ...result }))
            return refData
        },

        // getLanguages() {
        //     return state.languages.get()
        // },

        getCountries() {
            return state.countries ? state.countries.attach(Downgraded).get().sort((c1, c2) => compareStrings(c1.name, c2.name)) : []
        },

        getCategories(type: string, whitelist: string[]) {
            let categories = state.categories
                .get()
                .filter((category) => category && category.types && category.types.indexOf(type) >= 0 && !category.synthetic)
            if (!isNullOrEmpty(whitelist)) {
                categories = categories.filter((category) => category.id && whitelist.indexOf(category.id) >= 0)
            }
            return categories
        },

        getCategory(id: string) {
            return state.categoriesMap.get()[id]
        },

        findRootByCategoryId(id: string) {
            let category = state.categoriesMap.get()[id]
            while (category.parentId) {
                category = state.categoriesMap.get()[category.parentId]
            }
            return category
        },

        getPersonFunctions() {
            return state.personFunction.get()
        },

        getAccessMethod() {
            return state.accessMethod.get()
        },
    }
}
