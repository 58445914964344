export function repositionItem<T>(items: T[], item: T, oldIndex: number, newIndex: number) {
    return oldIndex > newIndex ?
        [...items.slice(0, newIndex), item, ...items.slice(newIndex, oldIndex), ...items.slice(oldIndex + 1)]
        :
        [...items.slice(0, oldIndex), ...items.slice(oldIndex + 1, newIndex + 1), item, ...items.slice(newIndex + 1)]
}

export function isNullOrEmpty<T>(items: T[] | null | undefined) {
    return !items || items.length === 0
}

export function mergeUInt8Arrays(a1: Uint8Array, a2: Uint8Array): Uint8Array {
    // sum of individual array lengths
    var mergedArray = new Uint8Array(a1.length + a2.length);
    mergedArray.set(a1);
    mergedArray.set(a2, a1.length);
    return mergedArray;
}