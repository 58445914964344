import { parseServerDateTime, formatDateTime, stripEmptyString } from "../utils/Dates";
import { concatStringWithSeparator, restoreLineBreaks, stripControlCharacters, stripNull } from "../utils/Strings";
import { ServiceKeys } from "./ConfigDataState";
import { Category, Coupons, EventDate, Job, Link, Media, News, Organization, Person, PrintCatalog, Product, Showrooms, Trademark, UploadResult } from "./ExhibitorDataState";
import { hasServiceKeys } from '../globalStates/ConfigDataState';
import { ReferenceDataState } from "./ReferenceDataState";

export function mapOrganization(data: any): any {
    return {
        ...mapProfile(data),
        products: mapProducts(data.products),
        trademarks: mapTrademarks(data.trademarks),
        newsList: mapNews(data.newsList),
        eventDates: mapEventDate(data.eventDates),
        showrooms: mapShorooms(data.showrooms),
        goodies: mapCoupons(data.goodies),
        jobOffers: mapJobs(data.jobOffers),
        printCatalog: mapPrintCatalog(data.printCatalog, data),
    }
}

function mapProfile(data: any) {
    return {
        ...data,
        description: restoreLineBreaks(data.description),
        descriptionDe: restoreLineBreaks(data.descriptionDe),
        teaser: restoreLineBreaks(data.teaser),
        teaserDe: restoreLineBreaks(data.teaserDe)
    }
}

function mapProducts(products: any[]): Product[] {
    if (!products) return products
    return products.map(product => {
        return {
            ...product,
            showFrom: mapDate(product.showFrom),
            showUntil: mapDate(product.showUntil),
            description: restoreLineBreaks(product.description),
            descriptionDe: restoreLineBreaks(product.descriptionDe),
            teaser: restoreLineBreaks(product.teaser),
            teaserDe: restoreLineBreaks(product.teaserDe)
        }
    })
}

function mapTrademarks(trademarks: any[]): Trademark[] {
    if (!trademarks) return trademarks
    return trademarks.map(trademark => {
        return {
            ...trademark,
            description: restoreLineBreaks(trademark.description),
            descriptionDe: restoreLineBreaks(trademark.descriptionDe),
            teaser: restoreLineBreaks(trademark.teaser),
            teaserDe: restoreLineBreaks(trademark.teaserDe)
        }
    })
}

function mapNews(newsList: any[]): News[] {
    if (!newsList) return newsList
    return newsList.map(news => {
        return {
            ...news,
            date: mapDate(news.date as string),
            description: restoreLineBreaks(news.description),
            descriptionDe: restoreLineBreaks(news.descriptionDe),
            teaser: restoreLineBreaks(news.teaser),
            teaserDe: restoreLineBreaks(news.teaserDe)
        }
    })
}

function mapEventDate(eventDates: any[]): EventDate[] {
    if (!eventDates) return eventDates
    return eventDates.map(eventDate => {
        return {
            ...eventDate,
            startdatetime: removeTimezoneData(eventDate.startdatetime),
            enddatetime: removeTimezoneData(eventDate.enddatetime),
            description: restoreLineBreaks(eventDate.description),
            descriptionDe: restoreLineBreaks(eventDate.descriptionDe),
            teaser: restoreLineBreaks(eventDate.teaser),
            teaserDe: restoreLineBreaks(eventDate.teaserDe),
            persons: mapPersonsOut(eventDate.persons)
        }
    })
}

function mapShorooms(showrooms: any[]): Showrooms[] {
    if (!showrooms) return showrooms
    return showrooms.map(showroom => {
        return {
            ...showroom,
            shortDescription: restoreLineBreaks(showroom.shortDescription),
            shortDescriptionDe: restoreLineBreaks(showroom.shortDescriptionDe),
        }
    })
}

function mapCoupons(coupons: any[]): Coupons[] {
    if (!coupons) return coupons
    return coupons.map(coupon => {
        return {
            ...coupon,
            validStart: mapDate(coupon.validStart),
            validEnd: mapDate(coupon.validEnd),
            descriptionTitle: restoreLineBreaks(coupon.descriptionTitle),
            descriptionTitleDe: restoreLineBreaks(coupon.descriptionTitleDe),
            descriptionLong: restoreLineBreaks(coupon.descriptionLong),
            descriptionLongDe: restoreLineBreaks(coupon.descriptionLongDe),
            descriptionShort: restoreLineBreaks(coupon.descriptionShort),
            descriptionShortDe: restoreLineBreaks(coupon.descriptionShortDe)
        }
    })
}

function mapJobs(jobs: any[]): Job[] {
    if (!jobs) return []
    return jobs.map(job => {
        return {
            ...job,
            startdate: mapDate(job.startdate as string),
            updated: mapDate(job.updated as string),
            showFrom: mapDate(job.showFrom as string),
            showUntil: mapDate(job.showUntil as string),
            description: restoreLineBreaks(job.description),
            descriptionDe: restoreLineBreaks(job.descriptionDe),
            requirements: restoreLineBreaks(job.requirements),
            requirementsDe: restoreLineBreaks(job.requirementsDe),
            homeOffice: mapTripleState(job.homeOffice)
        }
    })
}

function mapPrintCatalog(printCatalog: any, organization: any): PrintCatalog | undefined {
    if (!printCatalog) printCatalog = {
        adPages: []
    }

    if (!printCatalog.name) {
        printCatalog.name = organization.name
        printCatalog.nameImported = true
    } else printCatalog.nameImported = false

    let [address1, address2, address3] = parseAddress(printCatalog.address)
    if (!printCatalog.address && !printCatalog.postcode && !printCatalog.city && !printCatalog.countryCode) {
        address1 = organization.address1
        address2 = organization.address2
        address3 = organization.address3
        printCatalog.postcode = organization.postCode
        printCatalog.city = organization.city
        printCatalog.countryCode = organization.countryCode
        printCatalog.addressImported = true
    } else printCatalog.addressImported = false

    if (!printCatalog.phone && !printCatalog.mobile && !printCatalog.fax && !printCatalog.email && !printCatalog.web) {
        printCatalog.phone = organization.phone
        printCatalog.mobile = organization.mobile
        printCatalog.fax = organization.fax
        printCatalog.email = organization.email
        printCatalog.web = organization.web
        printCatalog.contactImported = true
    } else printCatalog.contactImported = false

    if (!printCatalog.description && !printCatalog.descriptionDe) {
        printCatalog.description = organization.teaser
        printCatalog.descriptionDe = organization.teaserDe
        printCatalog.descriptionImported = true
    } else printCatalog.descriptionImported = false

    return {
        ...printCatalog,
        address: undefined,
        address1,
        address2,
        address3,
        description: restoreLineBreaks(printCatalog.description),
        descriptionDe: restoreLineBreaks(printCatalog.descriptionDe)
    }
}

export function mapOrganizationOut(organization: Organization, serviceKeys: ServiceKeys, refDataState: ReferenceDataState): any {
    return {
        ...mapProfileOut(organization, serviceKeys),
        tags: hasServiceKeys(serviceKeys.keywords) ? organization.tags : undefined,
        categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(organization.categories, refDataState) : undefined,
        licences: hasServiceKeys(serviceKeys.category) ? filterSynthetic(organization.categories, refDataState) : undefined,
        links: mapLinksOut(organization.links, serviceKeys),
        products: mapProductsOut(organization.products, serviceKeys, refDataState),
        trademarks: mapTrademarksOut(organization.trademarks, serviceKeys, refDataState),
        persons: mapPersonsOutControlled(organization.persons, serviceKeys),
        medias: hasServiceKeys(serviceKeys.multimedia) ? mapMediasOut(organization.medias) : undefined,
        newsList: mapNewsOut(organization.newsList, serviceKeys, refDataState),
        eventDates: mapEventDatesOut(organization.eventDates, serviceKeys),
        showrooms: mapShoroomsOut(organization.showrooms),
        goodies: mapCouponsOut(organization.goodies, serviceKeys),
        jobOffers: hasServiceKeys(serviceKeys.jobAd) ? mapJobsOut(organization.jobOffers) : undefined,
        printCatalog: mapPrintCatalogOut(organization.printCatalog, serviceKeys, refDataState)
    }
}

function mapProfileOut(organization: Organization, serviceKeys: ServiceKeys) {
    return {
        ...organization,
        description: stripNull(stripControlCharacters(organization.description)),
        descriptionDe: stripNull(stripControlCharacters(organization.descriptionDe)),
        teaser: stripNull(stripControlCharacters(organization.teaser)),
        teaserDe: stripNull(stripControlCharacters(organization.teaserDe)),
        consentTitle: stripNull(stripControlCharacters(organization.consentTitle)),
        consentTitleDe: stripNull(stripControlCharacters(organization.consentTitleDe)),
        consentText: stripNull(stripControlCharacters(organization.consentText)),
        consentTextDe: stripNull(stripControlCharacters(organization.consentTextDe)),
        foundingYear: stripNull(stripControlCharacters(organization.foundingYear)),
        industry: stripNull(stripControlCharacters(organization.industry)),
        size: stripNull(stripControlCharacters(organization.size)),
        logoUrl: hasServiceKeys(serviceKeys.logo) ? organization.logoUrl : undefined,
        backgroundImageUrl: hasServiceKeys(serviceKeys.headerGraphic) ? organization.backgroundImageUrl : undefined,
        backgroundImageMobileUrl: hasServiceKeys(serviceKeys.headerGraphic) ? organization.backgroundImageMobileUrl : undefined,
        headerVideoImageUrl: hasServiceKeys(serviceKeys.headerVideo) ? organization.headerVideoImageUrl : undefined,
        headerVideoUrl: hasServiceKeys(serviceKeys.headerVideo) ? organization.headerVideoUrl : undefined,

        facebook: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.facebook)) ? organization.facebook : undefined,
        instagram: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.instagram)) ? organization.instagram : undefined,
        linkedIn: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.linkedIn)) ? organization.linkedIn : undefined,
        xing: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.xing)) ? organization.xing : undefined,
        twitter: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.twitter)) ? organization.twitter : undefined,
        youTube: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.youTube)) ? organization.youTube : undefined,
        pinterest: hasServiceKeys(serviceKeys.socialMedia) && stripNull(stripControlCharacters(organization.pinterest)) ? organization.pinterest : undefined,

        iframeUrl: hasServiceKeys(serviceKeys.iframe) && stripNull(stripControlCharacters(organization.iframeUrl)) ? organization.iframeUrl : undefined,
        iframeUrlDe: hasServiceKeys(serviceKeys.iframe) && stripNull(stripControlCharacters(organization.iframeUrlDe)) ? organization.iframeUrlDe : undefined
    }
}

function mapLinksOut(links: Link[], serviceKeys: ServiceKeys): any[] | undefined {
    const hasServiceKeyLink = hasServiceKeys(serviceKeys.link)
    if (hasServiceKeyLink) {
        return links.map(link => {
            return {
                ...link,
                text: stripNull(stripControlCharacters(link.text)),
                textDe: stripNull(stripControlCharacters(link.textDe)),
                url: stripNull(stripControlCharacters(link.url)),
                urlDe: stripNull(stripControlCharacters(link.urlDe)),
                id: Number(link.id) <= 0 ? undefined : link.id
            }
        })
    }
    return undefined
}

function mapProductsOut(products: Product[], serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    const hasServiceKeyProducts = hasServiceKeys(serviceKeys.products)
    if (hasServiceKeyProducts) {
        return products.map(product => {
            return {
                ...product,
                id: Number(product.id) <= 0 ? undefined : product.id,
                showFrom: product.showFrom && mapDateTimeOut(product.showFrom),
                showUntil: product.showUntil && mapDateTimeOut(product.showUntil),
                description: stripControlCharacters(product.description),
                descriptionDe: stripControlCharacters(product.descriptionDe),
                teaser: stripControlCharacters(product.teaser),
                teaserDe: stripControlCharacters(product.teaserDe),
                categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(product.categories, refDataState) : undefined,
                tags: hasServiceKeys(serviceKeys.keywords) ? product.tags : undefined,
                medias: mapMediasOut(product.medias)
            }
        })
    }
    return undefined
}

function mapTrademarksOut(trademarks: Trademark[], serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    const hasServiceKeyTrademarks = hasServiceKeys(serviceKeys.trademarks)
    if (hasServiceKeyTrademarks) {
        return trademarks.map(trademark => {
            return {
                ...trademark,
                id: Number(trademark.id) <= 0 ? undefined : trademark.id,
                description: stripControlCharacters(trademark.description),
                descriptionDe: stripControlCharacters(trademark.descriptionDe),
                teaser: stripControlCharacters(trademark.teaser),
                teaserDe: stripControlCharacters(trademark.teaserDe),
                categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(trademark.categories, refDataState) : undefined,
                tags: hasServiceKeys(serviceKeys.keywords) ? trademark.tags : undefined,
                medias: mapMediasOut(trademark.medias)
            }
        })
    }
    return undefined
}

function mapPersonsOut(persons: Person[]) {
    return persons.map(person => {
        return {
            ...person,
            title: stripNull(stripControlCharacters(person.title)),
            firstName: stripNull(stripControlCharacters(person.firstName)),
            midName: stripNull(stripControlCharacters(person.midName)),
            lastName: stripNull(stripControlCharacters(person.lastName)),
            salutation: stripNull(stripControlCharacters(person.salutation)),
            company: stripNull(stripControlCharacters(person.company)),
            position: stripNull(stripControlCharacters(person.position)),
            positionDe: stripNull(stripControlCharacters(person.positionDe)),
            email: stripNull(stripControlCharacters(person.email)),
            phone: stripNull(stripControlCharacters(person.phone)),
            fax: stripNull(stripControlCharacters(person.fax)),
            web: stripNull(stripControlCharacters(person.web)),
            description: stripNull(stripControlCharacters(person.description)),
            descriptionDe: stripNull(stripControlCharacters(person.descriptionDe)),
            teaser: stripNull(stripControlCharacters(person.teaser)),
            teaserDe: stripNull(stripControlCharacters(person.teaserDe)),
            id: Number(person.id) <= 0 ? undefined : person.id
        }
    })
}

function mapPersonsOutControlled(persons: Person[], serviceKeys: ServiceKeys) {
    const hasServiceKeyContactPartner = hasServiceKeys(serviceKeys.contactPartner)
    if (hasServiceKeyContactPartner) {
        return mapPersonsOut(persons)
    }
    return undefined
}

function mapMediasOut(medias: Media[]) {
    return medias.map(media => ({
        ...media,
        id: Number(media.id) <= 0 ? undefined : media.id
    }))
}

function mapNewsOut(newsList: News[], serviceKeys: ServiceKeys, refDataState: ReferenceDataState): any[] | undefined {
    const hasServiceKeysNews = hasServiceKeys(serviceKeys.news)
    if (hasServiceKeysNews) {
        return newsList.map(news => {
            return {
                ...news,
                id: Number(news.id) <= 0 ? undefined : news.id,
                description: stripControlCharacters(news.description),
                descriptionDe: stripControlCharacters(news.descriptionDe),
                teaser: stripControlCharacters(news.teaser),
                teaserDe: stripControlCharacters(news.teaserDe),
                date: mapDateTimeOut(news.date),
                categories: hasServiceKeys(serviceKeys.category) ? filterSynthetic(news.categories, refDataState) : undefined,
                tags: hasServiceKeys(serviceKeys.keywords) ? news.tags : undefined,
                medias: mapMediasOut(news.medias)
            }
        })
    }
    return undefined
}

function mapEventDatesOut(eventDates: EventDate[], serviceKeys: ServiceKeys) {
    return eventDates.map(eventDate => {
        return {
            ...eventDate,
            id: Number(eventDate.id) <= 0 ? undefined : eventDate.id,
            description: stripControlCharacters(eventDate.description),
            descriptionDe: stripControlCharacters(eventDate.descriptionDe),
            teaser: stripControlCharacters(eventDate.teaser),
            teaserDe: stripControlCharacters(eventDate.teaserDe),
            tags: hasServiceKeys(serviceKeys.keywords) ? eventDate.tags : undefined,
            medias: mapMediasOut(eventDate.medias),
            persons: mapPersonsOut(eventDate.persons)
        }
    })
}

function mapShoroomsOut(showrooms: Showrooms[]) {
    return showrooms.map(showroom => {
        return {
            ...showroom,
            id: Number(showroom.id) <= 0 ? undefined : showroom.id,
            shortDescription: stripControlCharacters(showroom.shortDescription),
            shortDescriptionDe: stripControlCharacters(showroom.shortDescriptionDe)
        }
    })
}

function mapCouponsOut(coupons: Coupons[], serviceKeys: ServiceKeys) {
    const hasServiceKeyGoodies = hasServiceKeys(serviceKeys.goodies)
    if (hasServiceKeyGoodies) {
        return coupons.map(coupon => {
            return {
                ...coupon,
                id: Number(coupon.id) <= 0 ? undefined : coupon.id,
                validStart: mapDateTimeOut(coupon.validStart),
                validEnd: mapDateTimeOut(coupon.validEnd),
                descriptionTitle: stripControlCharacters(coupon.descriptionTitle),
                descriptionTitleDe: stripControlCharacters(coupon.descriptionTitleDe),
                descriptionLong: stripControlCharacters(coupon.descriptionLong),
                descriptionLongDe: stripControlCharacters(coupon.descriptionLongDe),
                descriptionShort: stripControlCharacters(coupon.descriptionShort),
                descriptionShortDe: stripControlCharacters(coupon.descriptionShortDe)
            }
        })
    }
    return undefined
}

function mapJobsOut(jobs: Job[]) {
    return jobs.map(job => {
        return {
            ...job,
            id: Number(job.id) <= 0 ? undefined : job.id,
            description: stripNull(stripControlCharacters(job.description)),
            descriptionDe: stripNull(stripControlCharacters(job.descriptionDe)),
            requirements: stripNull(stripControlCharacters(job.requirements)),
            requirementsDe: stripNull(stripControlCharacters(job.requirementsDe)),
            startdate: stripEmptyString(mapDateTimeOut(job.startdate)),
            updated: mapDateTimeOut(job.updated),
            showFrom: stripEmptyString(mapDateTimeOut(job.showFrom)),
            showUntil: stripEmptyString(mapDateTimeOut(job.showUntil)),
            persons: mapPersonsOut(job.persons),
            medias: mapMediasOut(job.medias),
            homeOffice: mapTripleStateOut(job.homeOffice),
            location: stripNull(stripControlCharacters(job.location)),
            locationDe: stripNull(stripControlCharacters(job.locationDe)),
            employment: stripNull(stripControlCharacters(job.employment)),
            employmentDe: stripNull(stripControlCharacters(job.employmentDe)),
            careerLevel: stripNull(stripControlCharacters(job.careerLevel)),
            careerLevelDe: stripNull(stripControlCharacters(job.careerLevelDe)),
            salaryGroup: stripNull(stripControlCharacters(job.salaryGroup)),
        }
    })
}

function parseAddress(address: string): string[] {
    if (!address) return []
    return address.split("\n")
}

function composeAddress(printCatalog: PrintCatalog): string {
    let address = ''
    address = concatStringWithSeparator(address, printCatalog.address1, '\n')
    address = concatStringWithSeparator(address, printCatalog.address2, '\n')
    address = concatStringWithSeparator(address, printCatalog.address3, '\n')

    return address
}

function mapPrintCatalogOut(printCatalog: PrintCatalog, serviceKeys: ServiceKeys, refDataState: ReferenceDataState) {
    const printCatalogOut: any = {}

    const hasServiceKeyPrintLogo = hasServiceKeys(serviceKeys.printLogo)
    if ((hasServiceKeyPrintLogo)
        || (hasServiceKeys(serviceKeys.printLogoExhibitorDirectory))
        || (hasServiceKeys(serviceKeys.printLogoCategoryDirectory))) {
        printCatalogOut.logoFileId = printCatalog.logoFileId
        printCatalogOut.logoUrl = printCatalog.logoUrl
    }

    const hasServiceKeyPrintOrganizationName = hasServiceKeys(serviceKeys.printOrganizationName)
    if (hasServiceKeyPrintOrganizationName) {
        printCatalogOut.name = printCatalog.nameImported ? null : printCatalog.name
    }

    const hasServiceKeyPrintAddress = hasServiceKeys(serviceKeys.printAddress)
    const hasServiceKeyPrintAddressCategoryDirectory = hasServiceKeys(serviceKeys.printAddressCategoryDirectory)
    if (hasServiceKeyPrintAddress || hasServiceKeyPrintAddressCategoryDirectory) {
        printCatalogOut.address = printCatalog.addressImported ? null : composeAddress(printCatalog)
        printCatalogOut.postcode = printCatalog.addressImported ? null : printCatalog.postcode
        printCatalogOut.city = printCatalog.addressImported ? null : printCatalog.city
        printCatalogOut.countryCode = printCatalog.addressImported ? null : printCatalog.countryCode
    }

    const hasServiceKeyPrintContact = hasServiceKeys(serviceKeys.printContact)
    const hasServiceKeyPrintContactCategoryDirectory = hasServiceKeys(serviceKeys.printContactCategoryDirectory)
    if (hasServiceKeyPrintContact || hasServiceKeyPrintContactCategoryDirectory) {
        printCatalogOut.phone = printCatalog.contactImported ? null : printCatalog.phone
        printCatalogOut.fax = printCatalog.contactImported ? null : printCatalog.fax
        printCatalogOut.email = printCatalog.contactImported ? null : printCatalog.email
        printCatalogOut.web = printCatalog.contactImported ? null : printCatalog.web
    }

    const hasServiceKeyPrintDescription = hasServiceKeys(serviceKeys.printDescription)
    if (hasServiceKeyPrintDescription) {
        printCatalogOut.description = printCatalog.descriptionImported ? null : printCatalog.description
        printCatalogOut.descriptionDe = printCatalog.descriptionImported ? null : printCatalog.descriptionDe
    }

    const hasServiceKeyPrintAd = hasServiceKeys(serviceKeys.printAd)
    if (hasServiceKeyPrintAd) {
        printCatalogOut.adPages = printCatalog.adPages && printCatalog.adPages.map(adPage => ({
            ...adPage,
            id: Number(adPage.id) <= 0 ? undefined : adPage.id,
            name: undefined,
            adUrl: undefined
        }))
    }

    return {
        id: Number(printCatalog.id) <= 0 ? undefined : printCatalog.id,
        ...printCatalogOut
    }
}

function mapDate(dateString: string): Date | undefined {
    return parseServerDateTime(dateString)
}

function mapTripleState(triState: boolean): string | undefined {
    switch (triState) {
        case null: return 'na'
        case true: return 'yes'
        case false: return 'no'
    }
    return undefined
}

function removeTimezoneData(dateString: string): string | undefined {
    return dateString.split("[")[0]
}

/*function mapDateIso(dateString: string): Date | undefined {
    return new Date(dateString)
}*/

function mapDateTimeOut(dateTime: Date | undefined | number): string {
    return formatDateTime(dateTime)
}

function mapTripleStateOut(triState: string): boolean | null | undefined {
    switch (triState) {
        case 'na': return null
        case 'yes': return true
        case 'no': return false
    }
    return undefined
}

function filterSynthetic(categories: Category[], refDataState: ReferenceDataState) {
    return categories.filter((category) => category && !refDataState.getCategory(category.id)?.synthetic)
}

function parseXmlAttribute(xmlElement: Element | undefined, attributeName: string): string {
    if (xmlElement) {
        return xmlElement.getAttribute(attributeName) || '';
    }

    // attribute or element not found
    return ''
}

export function parseUploadXml(xml: string): UploadResult | null {
    const parser = new DOMParser();
    const exhibitorXml = parser.parseFromString(xml, 'text/xml')
    const uploadsXml = exhibitorXml.getElementsByTagName('upload')
    if (uploadsXml && uploadsXml.length >= 0) {
        return {
            fileId: parseXmlAttribute(uploadsXml[0], 'fileId'),
            fileUrl: parseXmlAttribute(uploadsXml[0], 'fileUrl'),
            fileName: parseXmlAttribute(uploadsXml[0], 'fileName'),
            contentType: parseXmlAttribute(uploadsXml[0], 'contentType'),
            size: parseXmlAttribute(uploadsXml[0], 'size'),
        }
    }
    return null
}

export function mockShowrooms(): Showrooms[] {
    return [{
        id: "showrooms1",
        orderShowrooms: 0,
        title: "Showrooms 1",
        titleDe: "Showrooms 1 De",
        logourl: "https://s3-eu-west-1.amazonaws.com/messe.backend.live/tectronica/personImages/p000006/logo_685cb3b538ec34efb85f01b4d502d913_0MHerzog.png",
        logourlDe: "https://s3-eu-west-1.amazonaws.com/messe.backend.live/tectronica/productImages/prod_030/logo_7b31f52cd2643376ebb2c92b3fa3aacf_467643365server1.jpg",
        accessMethod: "Br",
        shortDescription: "Short description\n 1",
        shortDescriptionDe: "Short description\n 1 De"
    },
    {
        id: "showrooms2",
        orderShowrooms: 1,
        title: "Showrooms 2",
        titleDe: "Showrooms 2 De",
        logourl: "https://s3-eu-west-1.amazonaws.com/messe.backend.live/tectronica/personImages/p000006/logo_685cb3b538ec34efb85f01b4d502d913_0MHerzog.png",
        logourlDe: "https://s3-eu-west-1.amazonaws.com/messe.backend.live/tectronica/productImages/prod_030/logo_7b31f52cd2643376ebb2c92b3fa3aacf_467643365server1.jpg",
        accessMethod: "Pb",
        shortDescription: "Short description\n 2",
        shortDescriptionDe: "Short description\n 2 De"
    }]
}
